<template>
<div>
  Capture
  <webrtc-2
    ref="webrtc"
    :user="{id:'test', name: 'You'}"
    :room="{id:'test', users:['test']}"
    @usersChange="webrtcUsersUpdate"/>
  <userlist :users="users"/>
  <hr/>
  Recording and Save
  <button class="btn btn-primary" @click="startRecording">Record</button>
  <button class="btn btn-danger" @click="stopRecording">Stop</button>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('test-audio');
import Userlist from '@/components/userlist.vue';
import webrtc2 from '@/components/webrtc2.vue'
import { saveAs } from 'file-saver';
import { cleanFilename } from "@/services/utils";

export default {
  components: { webrtc2, Userlist },
  data() {
    return {
      users: [],
      mediaRecorder: null,
      // TODO: Detect compatible audio format (iOS, Android)
      mimeType: "video/webm;codecs=h264,opus",
    }
  },
  mounted() {
    this.$debug.isOn = true;
  },
  methods: {
    webrtcUsersUpdate(users) {
      log.log("webrtcUsersUpdate");
      this.users = users;
    },
    startRecording() {
      log.log("startRecording", this.users);
      this.mediaRecorder = new MediaRecorder(this.users[0].stream, {
        mimeType: this.mimeType,
        videoBitsPerSecond : 3000000,
        sampleRate: 48000,
      });

      this.chunks = [];

      this.mediaRecorder.ondataavailable = (e) => {
        log.log("ondataavailable");
        this.chunks.push(e.data);
      };

      this.mediaRecorder.start(1000);
    },
    stopRecording() {
      log.log("stopRecording");
      this.mediaRecorder.stop();

      this.mediaRecorder.onstop = () => {
        log.log("onstop");
        let blob = new Blob(this.chunks, { 'type' : this.mimeType });
        let date = new Date().toISOString();
        saveAs(blob, `test-${cleanFilename(date)}.webm`);
      }
    }
  }
}
</script>

<style>

</style>